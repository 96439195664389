import React, { useState, useContext, useRef } from "react";
import "./booking.css";
import {  Button } from "reactstrap";
import {  Row, Col, Input } from "reactstrap";
import emailjs from '@emailjs/browser';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'

const Booking = ({ tour, avgRating }) => {
  const {  title } = tour;



  const [booking, setBooking] = useState({
    tourName: title,
    fullName: "",
    phone: "",
    message: "",
    email: "",

  });

  const handleChange = e => {
    setBooking(prev => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  //   send data to the server
  const handleClick = e => {
    e.preventDefault();
    setIsSubmitting(true);
    emailjs.sendForm('service_57x19j5', 'template_tcjckyk', form.current, 'x9r8_fY2CRt6sDtcg')
      .then((result) => {
        console.log(result.text);
        toast.success('thank you for your booking!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        form.current.reset();
        setIsSuccess(true);
      })
      .catch((error) => {
        toast.error('Failed to send message. Please try again later.', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setErrorMessage('Failed to send message. Please try again later.');

      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="booking">
      

      {/* ========== booking form ============= */}
      <div className="booking__form">
        <h5>Information</h5>
        <ToastContainer />
      

        <form ref={form} onSubmit={handleClick}>
          <Row>
            <Col sm="12" >
              <div className="from-wraper">
                <i className="ri-user-2-fill"></i>
                <Input
                  id="exampleName"
                  name="user_Name"
                  placeholder="Your Name"
                  type="text"
                />
              </div>
            </Col>
            <Col sm="12"  >
              <div className="from-wraper">
                <i className="ri-earth-fill"></i>
                <Input
                  id="exampleCountry"
                  name="user_Country"
                  placeholder="Your Country"
                  type="text"
                />
              </div>
            </Col>

            <Col sm="12"  >
              <div className="from-wraper">
                <i className="ri-mail-send-line"></i>
                <Input
                  id="exampleEmail"
                  name="user_email"
                  placeholder="Your email"
                  type="email"
                />
              </div>
            </Col>
            <Col sm="12"  >
              <div className="from-wraper">
                <i className="ri-phone-line"></i>
                <Input
                  id="examplePhone"
                  name="user_num"
                  placeholder="Your phone"
                  type="text"
                />
              </div>
            </Col>
            <Col sm="12">
              <div className="from-wraper">
                <i className="ri-calendar-2-line"></i>
                <Input
                  id="message"
                  name="message"
                  placeholder="Tell us what you need"
                  type="textarea"
                />
              </div>
            </Col>
            
          </Row>
          <Button type="submit" className="btn primary__btn w-100 mt-4" onClick={handleClick}>
          Book Now
          </Button>
          <div className="booking__bottom"> </div>  
        </form>
      </div>
      {/* ========== booking end ============= */}
    </div>
  );
};

export default Booking;
