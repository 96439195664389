// RelatedTours.js
import React from "react";
import { Row, Col } from "reactstrap";
import TourCard from "../shared/TourCard";

const RelatedTours = ({ relatedTours }) => {
  return (
    <section>
      
      <Row>
        {relatedTours.map((tour) => (
          <Col lg="3" md="6" sm="6" className="mb-4" key={tour.id}>
            <TourCard tour={tour} />
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default RelatedTours;
