import React, { useRef,useEffect } from "react";
import "./search-bar.css";
import { Col, Form, FormGroup } from "reactstrap";
import { useHistory } from "react-router-dom"; // Import useHistory

import { BASE_URL } from "./../utils/config";
import tours from "../assets/data/tours";

const SearchBar = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const city = useRef("");
  const nombreDay = useRef(0);
  const history = useHistory(); // Initialize useHistory

  const searchHandler = async () => {
    let location = city.current.value.toUpperCase();
    const maxGroupSize = nombreDay.current.value;

    if (location === "" || maxGroupSize === "") {
      return alert("All fields are required!");
    }
    

    // Use history.push to navigate to the desired route
    history.push(`/search?city=${location}&day=${maxGroupSize}`);
  };

  return (
    <Col lg="12">
      <div className="search__bar">
        <Form className="d-flex align-items-center gap-4">
          <FormGroup className="d-flex gap-3 form__group form__group-fast">
            <span>
              <i className="ri-map-pin-line"></i>
            </span>
            <div>
              <h6>Location</h6>
              <input
                type="text"
                placeholder="Where are you going?"
                ref={city}
              />
            </div>
          </FormGroup>

          <FormGroup className="d-flex gap-3 form__group form__group-last">
            <span>
              <i className="ri-calendar-line"></i>
            </span>
            <div>
              <h6>Number of Day</h6>
              <input type="number" placeholder="0" ref={nombreDay} />
            </div>
          </FormGroup>

          <span className="search__icon" type="submit" onClick={searchHandler}>
            <i className="ri-search-line"></i>
          </span>
        </Form>
      </div>
    </Col>
  );
};

export default SearchBar;
