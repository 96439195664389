import React, { useState,useEffect} from "react";
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import galleryImage from "./galleryImages";
import "../../styles/tour.css";
const MasonryImageGallery = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const [visibleImages, setVisibleImages] = useState(8); // Number of initially visible images

    const loadMoreImages = () => {
        setVisibleImages(prevVisibleImages => prevVisibleImages + 8); // Increase the number of visible images
    };

    return (
        <div>
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 576: 2, 768: 3, 992: 4 }}>
                <Masonry gutter="1rem">
                    {galleryImage.slice(0, visibleImages).map((item, index) => (
                        <img
                            className="masonry__img"
                            src={item}
                            key={index}
                            alt="tours in morocco images"
                            style={{
                                width: '100%',
                                display: 'block',
                            }}
                            loading="lazy"
                            title="morocco beautiful image" // Add the loading attribute for lazy loading
                        />
                    ))}
                </Masonry>
            </ResponsiveMasonry>

            {visibleImages < galleryImage.length && (
                <button className="show-more-button" onClick={loadMoreImages}>
                    Show More
                </button>
            )}
        </div>
    );
};

export default MasonryImageGallery;
