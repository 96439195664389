import React from "react";
import "./footer.css";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";

import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const quick__links = [
  {
    path: "/home",
    display: "Home",
  },
  {
    path: "/about",
    display: "About",
  },
  {
    path: "/tours",
    display: "Tours",
  },
  {
    path: "/day",
    display: "Day trip",
  },
  {
    path: "/contact",
    display: "Contact Us",
  },
];

const quick__links2 = [
  {
    path: "/gallery",
    display: "Gallery",
    external: false,
  },
   {
  path: "https://www.tripadvisor.com/Attraction_Review-g293732-d14179822-Reviews-Morocco_Happiness_Tours-Casablanca_Grand_Casablanca_Region.html",
  display: "Reviews",
  external: true,
   }
  
];

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer" id="about">
      <Container>
        <Row>
          <Col lg="3">
            <div className="logo">
            <div className="logo-img">
              <img src={logo} alt="" />
              </div>
              <p>
              We are a dedicated team of certified guides aiming to offer you the best travel experience in Morocco. With friendly staff and years of experience, we are here to help you make the most of your stay by offering private tours, customized to your own needs.
              </p>

              <div className="social__links d-flex align-items-center gap-4">
                <span>
                  <a href="https://www.youtube.com/@moroccohappinesstours214">
                    <i className="ri-youtube-line"></i>
                  </a>
                </span>
                <span>
                  <a href="https://web.facebook.com/Moroccohappinesstours/?ref=embed_page">
                    <i className="ri-facebook-circle-line"></i>
                  </a>
                </span>
                <span>
                  <a href="https://www.instagram.com/moroccohappiness_tours/">
                    <i className="ri-instagram-line"></i>
                  </a>
                </span>
                  <span>
                  <a href="https://api.whatsapp.com/send?phone=0671324712">
                    <i className="ri-whatsapp-line"></i>
                  </a>
                </span>
              
              
              </div>
            </div>
          </Col>

          <Col lg="3">
            <h5 className="footer__link-title">Discover</h5>

            <ListGroup className="footer__quick-links">
              {quick__links.map((item, index) => (
                <ListGroupItem key={index} className="ps-0 border-0">
                  <Link to={item.path}>{item.display}</Link>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          <Col lg="3">
            <h5 className="footer__link-title">Quick Links</h5>

            
            <ListGroup className="footer__quick-links">
              {quick__links2.map((item, index) => (
                <ListGroupItem key={index} className="ps-0 border-0">
                  {item.external ? (
                    <a href={item.path} target="_blank" rel="noopener noreferrer">
                      {item.display}
                    </a>
                  ) : (
                    <Link to={item.path}>{item.display}</Link>
                  )}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          <Col lg="3">
            <h5 className="footer__link-title">Contact</h5>

            <ListGroup className="footer__quick-links">
              <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-3">
                <h6 className="mb-0 d-flex align-items-center gap-2">
                  <span>
                    <i className="ri-map-pin-line"></i>
                  </span>
                  Address:
                </h6>

                <p className="mb-0 ">Casablanca_20000,Morocco</p>
              </ListGroupItem>
              <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-3">
                <h6 className="mb-0 d-flex align-items-center gap-2">
                  <span>
                    <i className="ri-mail-line"></i>
                  </span>
                  Email:
                </h6>

                <p className="mb-0">moroccohappinesstours@gmail.com</p>
              </ListGroupItem>
              <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-3">
                <h6 className="mb-0 d-flex align-items-center gap-2">
                  <span>
                    <i className="ri-phone-fill"></i>
                  </span>
                  Phone:
                </h6>

                <a href="tel:0671324712">0671324712</a>
              </ListGroupItem>
            </ListGroup>
          </Col>

          <Col lg="12" className="text-center pt-5">
            <p className="copyright">
              Copyright {year}, designed and developed by ELkola Loubna & EL Alaoui Oumaima
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
