import galleryImg01 from '../../assets/images/gallery/01.jpg'
import galleryImg02 from '../../assets/images/gallery/02.jpg'
import galleryImg03 from '../../assets/images/gallery/03.jpg'
import galleryImg04 from '../../assets/images/gallery/04.jpg'
import galleryImg05 from '../../assets/images/gallery/05.jpg'
import galleryImg06 from '../../assets/images/gallery/06.jpg'
import galleryImg07 from '../../assets/images/gallery/07.jpg'
import galleryImg08 from '../../assets/images/gallery/08.jpg'
import galleryImg09 from '../../assets/images/gallery/09.jpg'
import galleryImg10 from '../../assets/images/gallery/10.jpg'
import galleryImg11 from '../../assets/images/gallery/11.jpg'
import galleryImg12 from '../../assets/images/gallery/12.jpg'
import galleryImg13 from '../../assets/images/gallery/13.jpg'
import galleryImg14 from '../../assets/images/gallery/14.jpg'
import galleryImg15 from '../../assets/images/gallery/15.jpg'
import galleryImg16 from '../../assets/images/gallery/16.jpg'
import galleryImg17 from '../../assets/images/gallery/17.jpg'
import galleryImg18 from '../../assets/images/gallery/18.jpg'
import galleryImg19 from '../../assets/images/gallery/19.jpg'
import galleryImg20 from '../../assets/images/gallery/20.jpg'
import galleryImg21 from '../../assets/images/gallery/21.jpg'
import galleryImg22 from '../../assets/images/gallery/22.jpg'
import galleryImg23 from '../../assets/images/gallery/23.jpg'
import galleryImg24 from '../../assets/images/gallery/24.jpg'
import galleryImg25 from '../../assets/images/gallery/25.jpg'
import galleryImg26 from '../../assets/images/gallery/26.jpg'
import galleryImg27 from '../../assets/images/gallery/27.jpg'
import galleryImg28 from '../../assets/images/gallery/28.jpg'
import galleryImg29 from '../../assets/images/gallery/29.jpg'



const galleryImages =[
    galleryImg01 , 
    galleryImg02 , 
    galleryImg03  ,
    galleryImg04 , 
    galleryImg05  ,
    galleryImg06  ,
    galleryImg07  ,
    galleryImg08  ,
    galleryImg09  ,
    galleryImg10 ,
    galleryImg11  ,
    galleryImg12  ,
    galleryImg13  ,
    galleryImg14  ,
    galleryImg15  ,
    galleryImg16  ,
    galleryImg17  ,
    galleryImg18  ,
    galleryImg19  ,
    galleryImg20  ,
    galleryImg21  ,
    galleryImg22  ,
    galleryImg23  ,
    galleryImg24  ,
    galleryImg25  ,
    galleryImg26  ,
    galleryImg27  ,
    galleryImg28  ,
    galleryImg29  ,
]
 export default galleryImages;