import React,{ useEffect, useState }  from "react";
import "../styles/home.css";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "reactstrap";
import cap_kech from "../assets/images/capturekech.PNG"
import heroImg from "../assets/images/hero-img01.jpg";
import heroImg02 from "../assets/images/hero-img02.jpg";
import heroVideo from "../assets/images/hero-img03.jpg";
import worldImg from "../assets/images/maroc.jpg";
import experienceImg from "../assets/images/experience.jpg";
import Subtitle from "./../shared/Subtitle";
import SearchBar from "../shared/SearchBar";
import FeaturedTourList from "../components/Featured-tours/FeaturedTourList";
import Testimonials from "../components/Testimonial/Testimonials";
import Newsletter from "../shared/Newsletter";
import Fans from "../components/fans/fans";
import MasonryImageGallery from "../components/Image-gallery/MasonryImageGallery";
import CountUp from "react-countup";
import WhyChooseUs from '../components/whychooseus/why'
import SubtitleLink from './../shared/SubtitleLink'; // Import the SubtitleLink component

const Home = () => {
  const [mounted, setMounted] = useState(false);
  const [counted, setCounted] = useState(false);
  const [subtitleIndex, setSubtitleIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const subtitles = [
    "Explore Before Your Adventure",
    "Plan Ahead for Your Journey",
    "Uncover Hidden Gems",
    // Add more expressions as needed
  ];
  const heroImages1 = [
    heroVideo,
    heroImg02,
    heroImg 
    // Add more image URLs as needed
  ];
  const heroImages2 = [
   
    heroImg ,
    heroVideo,
    heroImg02
   
    // Add more image URLs as needed
  ];
  const heroImages3 = [
    heroImg02,
    heroImg ,
    heroVideo,
    // Add more image URLs as needed
  ];
  useEffect(() => {
    setMounted(true);
    window.scrollTo(0, 0);
  }, []);
  const handleScroll = () => {
    const element = document.getElementById("experience-section");
    if (element) {
      const rect = element.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
      if (isVisible && !counted) {
        setCounted(true);
      }
      if(!isVisible)
      {
        setCounted(false)
      }
    }
  };
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); 
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [counted]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Increment subtitleIndex and loop back to 0 when it exceeds the array length
      setSubtitleIndex((prevIndex) => (prevIndex + 1) % subtitles.length);
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % heroImages1.length);

    }, 1500); // Change subtitle every 1 second

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <title>
          Morocco Happiness Tours: Experience the Best Desert Tours in Morocco
        </title>

        <meta
          name="description"
          content="Morocco Happiness Tours offers the best desert tours from Marrakech, Fez and Casablanca. Book now and explore Morocco's beautiful cities and landscapes."
        />
        <meta
          name="keywords"
          content="Morocco tours, Marrakech desert trips, Sahara excursions, Marrakech day tours, Atlas Mountains, Fez tours, Casablanca tours, Imlil and Toubkal, 3 Valleys, Ourika Valley, Ouarzazate, Ait Ben Haddou, Essaouira, Ouzoud Waterfalls, Merzouga Desert, Sahara tour, Chefchaouen, Erg Chebbi Dunes, Imperial cities, Northern Morocco"
        />

        <meta
          name="apple-mobile-web-app-title"
          content="Morocco Happiness Tours"
        />
        <link
          rel="apple-touch-icon-precomposed"
          href="images/apple-touch-icon.png"
          title="Morocco Happiness Tours"
        />
        <link
          rel="apple-touch-icon"
          href="images/apple-touch-icon.png"
          title="Morocco Happiness Tours"
        />
        <link
          rel="shortcut icon"
          href="images/favicon.png"
          type="image/x-icon"
        />
        <link
          rel="canonical"
          href="https://www.moroccohappinesstours.com/index.js"
        />

        <meta name="og:site_name" content="Morocco Happiness Tours" />
        <meta
          name="og:title"
          content="Morocco Sahara Desert Tours, Tours From Marrakech"
        />
        <meta name="og:url" content="https://www.moroccohappinesstours.com" />
        <meta
          name="og:image"
          content="https://www.moroccohappinesstours.com/img/logo.png"
        />
        <meta name="og:type" content="Travel" />
        <meta name="og:locality" content="Casablanca" />
        <meta name="og:description" content="Desert Tours from Marrakech" />

        <meta
          property="article:publisher"
          content="https://www.facebook.com/Morocco-Happiness-Tours-331099394158870/"
        />
        <meta
          property="fb:page_id"
          content="https://www.facebook.com/Morocco-Happiness-Tours-331099394158870/"
        />
        <meta property="og:site_name" content="Morocco Happiness Tours" />
        <meta
          property="og:image"
          content="https://www.moroccohappinesstours.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://www.moroccohappinesstours.com/"
        />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Morocco Happiness Tours, Morocco Desert Tours"
        />
        <meta
          property="og:description"
          content="3 Days Tour To Merzouga Desert Via Atlas Mountain, 3 Days Tour From Marrakech Via The Sahara Desert To Fez, 4 Days Tour From Marrakesh Through Merzouga Desert To Fez, 4 Days tour from Marrakech to Marrakech via Merzouga desert, 7 Days Tour From Marrakech Via Desert And Imperial Cities"
        />
        <meta
          name="Identifier-url"
          content="https://www.moroccohappinesstours.com/"
        />
        <meta name="owner" content="moroccohappinesstours@gmail.com" />
        <meta name="Robots" content="all" />
        <meta name="Revisit-after" content="5 days" />
      </Helmet>

      {/* ========== hero section start =========== */}
      <section>
        <Container>
          <Row>
    <Col lg="6" className={`hero__content ${mounted ? 'fade-in' : ''}`}>
              <div className="hero__content">
                <div className="hero__subtitle d-flex align-items-center ">
                <Subtitle subtitle={subtitles[subtitleIndex]} />
                  <img src={worldImg} alt="world images" loading="lazy" title="world image"/>
                </div>
                <h1>
                  Morocco Happiness
                  <span className="highlight"> Tours</span>
                </h1>
                <p>
                  Morocco, a culturally rich and diverse nation, is renowned for
                  its welcoming attitude towards various cultures, ideas, and
                  lifestyles. It boasts a vibrant civilization and is home to
                  some of the most hospitable people.
                </p>
              </div>
            </Col>

            <Col lg="2">
              <div className="hero__img-box">
                <img  src={heroImages1[currentImageIndex]} alt="Hassan 2 tour in morocco casablanca" loading="lazy" title="hassan two tour"/>
              </div>
            </Col>
            <Col lg="2">
              <div className="hero__img-box hero__video-box mt-4">
                <img  src={heroImages2[currentImageIndex]} alt="cascade ouzoud morocco " controls loading="lazy" title="ouzoud morocco"/>
              </div>
            </Col>
            <Col lg="2">
              <div className="hero__img-box mt-5">
                <img src={heroImages3[currentImageIndex]} alt="desert's camels morocco" loading="lazy" title="desert morocco"/>
              </div>
            </Col>
          
            <SearchBar />
          </Row>
        </Container>
      </section>
      {/* ========== hero section end =========== */}


      {/* ============ featured tour section start ============ */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title">
                Discover the Magic of Morocco{" "}
              </h2>
            </Col>
            <FeaturedTourList />
          </Row>
        </Container>
      </section>
      {/* ============ featured tour section end ============ */}

      {/* ============ experience section start ============= */}
      <section id="experience-section">
        <Container>
          <Row>
            <Col lg="6">
              <div className="experience__content">
                <Subtitle subtitle={"Experience"} />

                <h2>
                  Experience at Your Service
                  <br />
                </h2>
                <p>
                  With an extensive track record predating the launch of our
                  website, Morocco Happiness Tours boasts a wealth of experience
                  in providing exceptional tour services.
                  <br />
                  Our remarkable reputation in the tourist market is a testament
                  to our unwavering commitment to customer satisfaction.
                </p>
              </div>

              <div className="counter__wrapper d-flex align-items-center gap-5">
                {counted && (
                  <>
                    <div className="counter__box">
                      <CountUp end={2000} duration={2} separator="." />
                      <h6>Successful trip</h6>
                    </div>
                    <div className="counter__box">
                      <CountUp end={24} duration={4} />
                      <h6>Programs</h6>
                    </div>
                    <div className="counter__box">
                      <CountUp end={15} duration={4} />
                      <h6>Years experience</h6>
                    </div>
                  </>
                )}
              </div>
            </Col>
            <Col lg="6">
              <div className="experience__img">
                <img 
                src={experienceImg} 
                alt="morocco's desert and camel"
                className={counted ? 'loop-animation' : ''}
                loading="lazy" 
                title="camels" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* ============ experience section end ============= */}
      {/*why choose us start =================================*/}
      <Container>
          <Row>
<Col lg="8" className="mb-2">
            <Subtitle subtitle={"Why Morocco Happiness Tours"} />
           
            </Col>
            </Row>
            </Container>
            < WhyChooseUs/>
           
{/*why choose us end =================================*/}

      {/* ============ testimonial section start ========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12">
              <Subtitle subtitle={"Fans Love"} />
              <h2 className="testimonial__title">What our fans say about us</h2>
            </Col>
            <Col lg="12">
              <Fans />
            </Col>
          </Row>
        </Container>
      </section>
      {/* ============ testimonial section end ========== */}
      {/* ============ gallery section start ============= */}

      <section>
        <Container>
          <Row>
            <Col lg="12">
              <Subtitle subtitle={"Gallery"} />
              <h2 className="gallery__title">
                Visit our custommers tour gallery
              </h2>
            </Col>

            <Col lg="12">
              <MasonryImageGallery />
            </Col>
          </Row>
        </Container>
      </section>

      {/* ============ gallery section end ============= */}
   

      <Newsletter />
         {/************************************links */}
     <section>
        <Container>
        <Row>
        <Col lg="6">
        <div className="experience__img">
        <SubtitleLink src={cap_kech} href="toursdetails/09" />
        </div>
 
  </Col>
  
          </Row>
        </Container>
        </section>
    </>
  );
};

export default Home;
