import React from "react";
import { Switch, Route,Router, Redirect } from "react-router-dom";
import Routers from "../../router/Routers";
import Login from "../../pages/login/Login";
import AdminRouter from "../../router/AdminRouter";

const Layout = () => {
  return (
    <>
    <Routers/>
   
     

   

      
      
    </>
  );
};

export default Layout;
