import React, { useState, useEffect } from "react";
import CommonSection from "../shared/ImageSlider";
import tourData from "../assets/data/tours";
import "../styles/about.css";
import SearchBar from "./../shared/SearchBar";
import Newsletter from "./../shared/Newsletter";
import { Container, Row, Col } from "reactstrap";
import img from "../assets/images/logo.png";
import { Helmet } from "react-helmet-async";
import ImageSlider from "../shared/ImageSlider";
import Subtitle from "./../shared/Subtitle";

const About = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const AboutImages = [{ url: img }];

  return (
    <>
      <Helmet>
        <meta charset="utf-8" lang="eng" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />

        <title>About Morocoo Happiness Tours </title>
        <meta
          name="description"
          content="independent company in Morocco. We believe the best way for customers to experience the authentic Moroccan culture is through the local people and therefore"
        />
        <meta
          name="keywords"
          content=",Enjoy,Vaccance,Holiday,Tours,Travel,Morocco Tourist,Tourist,Activities In Marrakech, Trips From Marrakech, Tours From Marrakech, Tours From Fez, Tours From Casablanca, Day Tour To Imlil And Toubkal In High Atlas Mountains From Marrakech, Day Tour To 3 Valleys And Atlas Mountains Trip From Marrakech, Day Tour To Ourika Valley And The High Atlas Mountains From Marrakech, Day Tour To Ouarzazate And Ait Ben Haddou Old Kasbahs From Marrakech, Day Tour To Essaouira The Ancient Mogador City And Coast From Marrakech, Day Tour To Ouzoud Waterfalls & The Middle Atlas Mountain From Marrakech, 2 Days Trip From Marrakech To Zagora Desert Sahara, 3 Days Tour To Merzouga Desert Via Atlas Mountain, 3 Days Tour From Marrakech Via The Sahara Desert To Fez, 4 Days Tour From Marrakesh Through Merzouga Desert To Fez, 4 Days tour from Marrakech to Marrakech via Merzouga desert, 7 Days Tour From Marrakech Via Desert And Imperial Cities, 2 Days Tour From Fes To Explore The Chefchaouen City, 3 Days From Fes To Marrakech Tour Via Erg Chebbi Dunes, 3 Days Desert Tour From Fes To Merzouga And Back To Fes, 4 Days Tour From Fes To Marrakech Via Merzouga Desert, 5 Days Tour From Fez Via Merzouga Desert To Marrakech, 6 Days Tour From Fes To Modern Cities In Northern Morocco"
        />
        <link
          rel="canonical"
          href="https://www.moroccohappinesstours.com/about"
        />
        <meta
          name="apple-mobile-web-app-title"
          content="Morocco Happiness Tours"
        />

        <meta name="og:site_name" content="Morocco Happiness Tours" />
        <meta
          name="og:title"
          content="Morocco Sahara Desert Tours, Tours From Marrakech"
        />
        <meta name="og:url" content="https://www.moroccohappinesstours.com" />
        <meta
          name="og:image"
          content="https://www.moroccohappinesstours.com/img/logo.png"
        />
        <meta name="og:type" content="Travel" />
        <meta name="og:locality" content="Casablanca" />
        <meta name="og:description" content="Desert Tours from Marrakech" />
      </Helmet>

      <Container>
        <ImageSlider images={AboutImages} />

        <div className="tour_info mt-4">
          <div className="row">
            <div className="col-md-12">
              <h2 className="headline with-border margin-top-0 margin-bottom-35">
                <Subtitle subtitle={"About Us"} />
              </h2>

              <p>
                We are independent company in Morocco. We believe the best way
                for customers to experience the authentic Moroccan culture is
                through the local people and therefore we emphasize on offering
                only certified local guides. As all our tours are private tours,
                we offer a very flexible service, all to meet the needs of the
                customers. We can start anywhere and go to everywhere,
                destinations and duration can all be tailored to the wishes of
                the customer.
                <br />
                <br />
                We are a dedicated team of certified guides aiming to offer you
                the best travel experience in Morocco. With friendly staff and
                years of experience, we are here to help you make the most of
                your stay by offering private tours, customized to your own
                needs.
                <br />
                <br />
                <b>Mission : </b>
                <br />
                Happiness is not a destination but is a way of travelling. Our
                mission is to deliver the best possible personal service to our
                customers; and by doing this, we help them find the path to
                happiness through unforgettable tour in Morocco.
                <br />
                <br />
                <b>Vision : </b>
                <br />
                To be the most trusted and respected tour company in our market
                and continuously provide the best in className personal service.
                We are flexible and we will give the extra effort needed to
                provide excellent experience for our customers.
                <br />
                <br />
                <b>Respect : </b>
                <br />
                Treat all customers and the people from the local community we
                work in with full respect in all aspects regardless of
                nationality, background, gender or religion. The human diversity
                makes the world a beautiful place.
                <br />
                <br />
                <b>Integrity : </b>
                <br />
                We deliver what we promise and only promise what we can deliver.
                We want to be partners to our clients, colleagues and the
                community and do our best to support the local community where
                the need is the most. We want to treat all our partners with
                fairness and respect.
                <br />
                <br />
                <b>Professionalism : </b>
                <br />
                To provide professional service by offering certified guides
                with the capabilities to address all situations in professional
                manner. All our customers should feel like they are safe, secure
                and our guides listen to their wishes.
              </p>
            </div>
          </div>
        </div>
      </Container>

      <Newsletter />
    </>
  );
};

export default About;
