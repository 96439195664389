import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Home from  "../pages/Home.jsx"
import Tours from "./../pages/Tours";
import TourDetails from "./../pages/TourDetails";
import About from "../pages/About";
import SearchResultList from "./../pages/SearchResultList";
import ThankYou from "../pages/ThankYou";
import Activite from "../pages/Activite";
import Contact from "../pages/Contact";
import MasonryImageGallery from "../components/Image-gallery/MasonryImageGallery";
import DayTour from "../pages/DayTour";
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer";
const isAdminRoute = (path) => {
  return path.includes("Admin"); 
};
const Routers = () => {
  
  return (
    <>
    
<Header />
   <Switch>
      <Route path="/home" component={Home } />
      <Route path="/tours" component={Tours } />
      <Route path="/toursdetails/:id" component={TourDetails} />
      <Route path="/about" component={About} />
      <Route path="/gallery" component={MasonryImageGallery } />
      <Route path="/thank-you" component={ThankYou } />
      <Route path="/search" component={SearchResultList } />
      <Route path="/activite" component={Activite } />
      <Route path="/contact" component={Contact } />
      <Route path="/day" component={DayTour } />
      <Redirect from="/" to="/home" />

      </Switch>

   
   
     
<Footer />


    
    

    </>
  );
};

export default Routers;
