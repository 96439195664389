import React, { useState, useEffect } from "react";
import ImageSlider from "../shared/ImageSlider";
import tourData from "../assets/data/tours"
import "../styles/tour.css";
import TourCard from "./../shared/TourCard";
import SearchBar from "./../shared/SearchBar";
import Newsletter from "./../shared/Newsletter";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet-async";
import useFetch from "../hooks/useFetch";
import { BASE_URL } from "../utils/config";


import img from "../assets/images/03.jpg";
import img2 from "../assets/images/05.jpg";
import img3 from "../assets/images/tagine.jpg"
import img4 from "../assets/images/01.jpg"


const Activite = () => {

    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(0);

    // const {
    //     data: Activite,
    //     loading,
    //     error,
    // } = useFetch(`${BASE_URL}/programms`);
    // const { data: tourCount } = useFetch(`${BASE_URL}/Activite/search/getTourCount`);

    useEffect(() => {
        const pages = Math.ceil(5 / 4);
        setPageCount(pages);
        window.scrollTo(0, 0);
    }, [page, 1, Activite]);
    const ActivitiesImages = [
        { url: img },
        { url: img2 },
        { url: img3 },
        { url: img4 },
    ];
    return (
        <>
            <Helmet>

                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

                <title>Activities & Things to do in Marrakech : Activities Starting From Marrakech, Activities From Marrakech</title>
                <meta name="description" content="Morocco Activities From Marrakech, Activities Starting From Marrakech, Activities From Marrakech " />
                <meta name="keywords" content="Desert Activities From Marrakech, Activities From Marrakech, Morocco Activities From Marrakech, Activities Starting From Marrakech, Activities From Marrakech, Activities to Desert From Marrakech,Activities in Marrakech, Marrakech Activities,Marrakech,Activities" />
                <link
                    rel="canonical"
                    href="https://www.moroccohappinesstours.com/activite"
                />
                <meta name="apple-mobile-web-app-title" content="Morocco Happiness Tours" />

                <meta name="og:site_name" content="Morocco Happiness Tours" />
                <meta name="og:title" content="Morocco Sahara Desert Tours, Tours From Marrakech" />
                <meta name="og:url" content="https://www.moroccohappinesstours.com" />
                <meta name="og:image" content="https://www.moroccohappinesstours.com/img/logo.png" />
                <meta name="og:type" content="Travel" />
                <meta name="og:locality" content="Casablanca" />
                <meta name="og:description" content="Desert Tours from Marrakech" />
            </Helmet>
            <ImageSlider images={ActivitiesImages} />
            <section>
                <Container>
                    <Row>
                        <SearchBar />
                    </Row>
                </Container>
            </section>
            <section className="pt-0">
                <Container>
                    {/* {loading && <h4 className="text-center pt-5">Loading.....</h4>} */}
                    {/* {error && <h4 className="text-center pt-5">{error}</h4>} */}

                    {/* //  !loading && !error &&  */}

                    <Row>
                        {tourData && tourData
                            .filter(tour => tour.type.some(type => type.name === "Activité"))
                            .map(tour => (
                                <Col lg="3" md="6" sm="6" className="mb-4" key={tour.id}>
                                    <TourCard tour={tour} />
                                </Col>
                            ))
                        }



                        <Col lg="12">
                            <div className="pagination d-flex align-items-center justify-content-center mt-4 gap-3">
                                {[...Array(pageCount).keys()].map(number => (
                                    <span
                                        key={number}
                                        onClick={() => setPage(number)}
                                        className={page === number ? "active__page" : ""}
                                    >
                                        {number + 1}
                                    </span>
                                ))}
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
            <Newsletter />
        </>
    );
};

export default Activite;
