import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
function ImageSlider({ title,images }) {
  return (
    <Carousel autoPlay={true} interval={500}>
      {images.map((image, index) => (
        <div key={index}>
          <img src={image.url} alt={`morocco tours and trip deserts and montain `} loading='lazy' title={`morocco tours image ${index}`} />
      
        </div>
      ))}
    </Carousel>
  );
}

export default ImageSlider;
