// ImageLink.jsx

import React from 'react';

const SubtitleLink = ({ src, href }) => {
  const handleClick = () => {
    // Redirect the user when the image is clicked
    window.location.href = href;
  };

  return (
    <div className="image-link" onClick={handleClick}>
      <img className="animated-image" src={src} alt="tours in morocco" />
    </div>
  );
};



export default SubtitleLink;
