import React, { useState } from 'react';
import "./FAQ.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Subtitle from '../../shared/Subtitle';

const FAQ = () => {
  // Define your FAQ data
  const faqData = [
    {
      question: 'What services do you offer ?',
      answer: 'We offer a variety of services including guided tours, private tours, and customized travel experiences.',
    },
    {
      question: 'What are the most amazing place to visit ?',
      answer: 'Marrakech vity , merzouga desert and.........................',
    },
    // Add more FAQ entries as needed
  ];

  // State to keep track of the open/closed status of each FAQ item
  const [openIndex, setOpenIndex] = useState(null);

  // Function to handle click on an FAQ item
  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  return (
    <div className="faq-container">
      <h2>
        <Subtitle subtitle={"Frequently Asked Questions"} />
      </h2>
      {faqData.map((faq, index) => (
        <div className={`faq-item ${openIndex === index ? 'clicked' : ''}`} key={index}>
          <div className="faq-question" onClick={() => handleToggle(index)}>
            <h4 >{faq.question}</h4>
            <span>
              {openIndex === index ? (
                <FontAwesomeIcon icon={faQuestionCircle} />
              ) : (
                '?'
              )}
            </span>
          </div>
          {openIndex === index && (
            <div className="faq-answer">
              <p>{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
          }

export default FAQ;
