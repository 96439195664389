import React from "react";
import "./fans.css";
import ava01 from "../../assets/images/ava-1.jpg";
import ava02 from "../../assets/images/ava-2.jpg";
import ava03 from "../../assets/images/ava-3.jpg";
import ava04 from "../../assets/images/ava-4.jpg";
import Slider from "react-slick";
import { FaShare } from 'react-icons/fa';
import { FaTripadvisor } from 'react-icons/fa';

const Fans = () => {
 
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 2000,
        swipeToSlide: true,
        draggable: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                  
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: true,

                },
            },
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,

                 },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                 },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
            },
            // Add more breakpoints and settings as needed
        ],
        
            
       
    };

    const justifiedText = {
        textAlign: 'left',
    }

    const castumIcone = {
        fontSize: '40px',
        marginRight: '30px',
        marginTop: '-10px',
        color: '#56A711'
    }
    const customContainer =
    {
        display: 'flex',
        marginLeft: '-50px',
    }

    return (


        <div className="fullwidth-carousel-container margin-top-20 ">
            <div className="testimonial-carousel ">
                <Slider className='slide' {...settings} >
                    {/* <!-- Item --> */}
                    <div className="fw-carousel-review ">
                        <div className="testimonial-box">

                            <div className="testimonial">
                                <div style={customContainer}>
                                    <FaShare style={castumIcone} />
                                    <h5>Unforgettable experience! - Highly recommend</h5>
                                </div>
                                <p style={justifiedText}>
                                    Morocco was the second half of an incredible
                                    trip that first took us around the gorgeous Andalusian
                                    landscape of southern Spain. We really didn’t know exactly
                                    what laid ahead of us as our ferry crossed the Strait of Gibraltar toward Tangier.....
                                </p>
                            </div>

                            <a href="https://www.tripadvisor.com/Attraction_Review-g293732-d14179822-Reviews-Morocco_Happiness_Tours-Casablanca_Grand_Casablanca_Region.html" target="_blank" title="Tripadvisor">
                                <div className="art-tripadvisor">
                                    <FaTripadvisor />

                                </div>
                            </a>
                            <div class="arrow"></div>

                        </div>
                        <div className="testimonial-author">
                            <img src={ava01} loading="lazy" alt="Shockandappall" />
                            <h4>Shockandappall <span>New York City, New York</span></h4>
                        </div>
                    </div>


                    {/* <!-- Item --> */}
                    <div className="fw-carousel-review">
                        <div className="testimonial-box">
                            <div className="testimonial">
                                <div style={customContainer}>
                                    <FaShare style={castumIcone} />
                                    <h5>Best tour in over 30 countries of travel!</h5>
                                </div>
                                <p style={justifiedText}>
                                    For the past four years I've travelled as much
                                    as possible whenever and wherever I can, spending
                                    about a year total wandering around. I've done a lot
                                    of tours and had a lot of guides - this was THE BEST I've
                                    ever experienced, hands down.....
                                </p>
                            </div>

                            <a href="https://www.tripadvisor.com/Attraction_Review-g293732-d14179822-Reviews-Morocco_Happiness_Tours-Casablanca_Grand_Casablanca_Region.html" target="_blank" title="Tripadvisor"><div className="art-tripadvisor">
                                <FaTripadvisor />
                            </div></a>
                            <div class="arrow"></div>

                        </div>
                        <div className="testimonial-author">
                            <img src={ava02} loading="lazy" alt="SuitcaseSixSarah" />
                            <h4>SuitcaseSixSarah <span>Bloomington, Indiana</span></h4>
                        </div>
                    </div>

                    {/* <!-- Item --> */}
                    <div className="fw-carousel-review">
                        <div className="testimonial-box">
                            <div className="testimonial">
                                <div style={customContainer}>
                                    <FaShare style={castumIcone} />
                                    <h5>Wonderful Tour & an Unforgettable Experience</h5>
                                </div>
                                <p style={justifiedText}>
                                    Thanks to Hicham, our time in Morocco was nothing short of amazing.
                                    From picking us up in our Riad to driving through the majestic Atlas Mountains
                                    and the Sahara Desert, Hicham always made sure we were getting the best experience possible.....
                                </p>
                            </div>

                            <a href="https://www.tripadvisor.com/Attraction_Review-g293732-d14179822-Reviews-Morocco_Happiness_Tours-Casablanca_Grand_Casablanca_Region.html" target="_blank" title="Tripadvisor">
                                <div className="art-tripadvisor">
                                    <FaTripadvisor />
                                </div></a>
                            <div class="arrow"></div>

                        </div>
                        <div className="testimonial-author">
                            <img src={ava03} loading="lazy" alt="melissa_cee123" />
                            <h4>Melissa_cee123 <span>Scottsdale, Arizona</span></h4>
                        </div>
                    </div>

                    {/* <!-- Item --> */}
                    <div className="fw-carousel-review">
                        <div className="testimonial-box">
                            <div className="testimonial">
                                <div style={customContainer}>
                                    <FaShare style={castumIcone} />
                                    <h5>Tour of a Lifetime - 100% Recommend!</h5>
                                </div>
                                <p style={justifiedText}>
                                    Having traveled to India, the Caribbean and various parts of Europe,
                                    Morocco seemed fascinating and was at the top of my list when it came time
                                    to decide where to go next. I received a recommendation from a respected colleague
                                    to book MoroccoHappinessTours.....
                                </p>
                            </div>

                            <a href="https://www.tripadvisor.com/Attraction_Review-g293732-d14179822-Reviews-Morocco_Happiness_Tours-Casablanca_Grand_Casablanca_Region.html" target="_blank" title="Tripadvisor"><div className="art-tripadvisor">
                                <FaTripadvisor />
                            </div></a>
                            <div class="arrow"></div>

                        </div>
                        <div className="testimonial-author">
                            <img src={ava04} loading="lazy" alt="Dylewalker" />
                            <h4>Dylewalker <span>New York City, New York</span></h4>
                        </div>
                    </div>

                </Slider>
            </div>
        </div>




    )
}

export default Fans;