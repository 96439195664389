import React, { useState } from 'react';
import './why.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faDollarSign, faHeart, faMapMarkedAlt, faCar, faComments } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from "reactstrap";

const WhyChooseUs = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (index) => {
    setSelectedCard(index);
  };

  const isCardSelected = (index) => {
    return selectedCard === index;
  };

  const cardData = [
    {
      title: 'Easy Booking',
      description: 'Effortlessly secure your reservations, making your journey planning a stress-free experience.',
      icon: faCalendarPlus,
    },
    {
      title: 'Value for Money',
      description: 'Get the most out of your investment with affordable excellence.',
      icon: faDollarSign,
    },
    {
      title: 'Passionate Travel',
      description: 'We guarantee every adventure is crafted with passion and dedication by our devoted team.',
      icon: faHeart,
    },
    {
      title: 'Awesome Places',
      description: 'Discover extraordinary destinations with us - where every place is a journey of its own.',
      icon: faMapMarkedAlt,
    },
    {
      title: 'Diverse Destinations',
      description: ' Embark on diverse adventures tailored to varied landscapes and cultures.',
      icon: faCar,
    },
    {
      title: '24 x 7 Support',
      description: 'Enjoy hassle-free journeys with 24/7 support, dedicated to your travel ease.',
      icon: faComments,
    },
  ];

  return (
    <div className="why-choose-us">
      <Container>
        <Row>
          {cardData.map((card, index) => (
            <Col lg="4" md="6" key={index}>
              <div
                className={`col-card ${isCardSelected(index) ? 'selected' : ''}`}
                onClick={() => handleCardClick(index)}
              >
                <div className="icon-container">
                  <div className="icon">
                    <FontAwesomeIcon icon={card.icon} size="4x" style={{ color: isCardSelected(index) ? '#fff' : '#eb3b35' }} />
                  </div>
                </div>
                <div className="detail">
                  <h4 style={{ color: isCardSelected(index) ? '#fff' : '#333' }}>{card.title}</h4>
                  <p style={{ color: isCardSelected(index) ? '#fff' : '#777' }}>{card.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default WhyChooseUs;
