import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link} from 'react-router-dom';
import { Container, Typography, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '../../components/table/Table'; 
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar'
import axiosInstance from '../axiosInstance';
function Days() {
  const [days, setDays] = useState([]);
  const [programs, setPrograms] = useState({});

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'titleday', headerName: 'Title', flex: 1 },
    { field: 'content', headerName: 'Content', flex: 1 },
    { field: 'program', headerName: 'Program', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => {
        const dayId = params.row.id;
        return (
          <>
            <IconButton component={Link} to={`/edit-day/${dayId}`} color="primary">
              <EditIcon sx={{ color: 'black' }} />
            </IconButton>
            <IconButton onClick={() => handleDelete(dayId)} color="error">
              <DeleteIcon sx={{ color: '#ed9e8e' }} />
            </IconButton>
          </>
        );
      },
    },
  ];

  const rows = days.map((day) => ({
    id: day.id,
    titleday: day.titleday,
    content: day.content,
    program: programs[day.idProgram],
  }));

  const fetchDays = async () => {
    try {
      const response = await axios.get('https://front.moroccohappinesstours.com/back/days');
      setDays(response.data);
    } catch (error) {
      console.error('Error fetching days:', error);
    }
  };

  const fetchPrograms = async () => {
    try {
      const response = await axios.get('https://front.moroccohappinesstours.com/back/programs');
      const programsData = response.data.reduce((acc, program) => {
        acc[program.id] = program.title;
        return acc;
      }, {});
      setPrograms(programsData);
    } catch (error) {
      console.error('Error fetching programs:', error);
    }
  };

  useEffect(() => {
    fetchDays();
    fetchPrograms();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this day?');
    if (confirmDelete) {
      try {
        await axiosInstance.delete(`/days/${id}`);
        fetchDays();
      } catch (error) {
        console.error('Error deleting day:', error);
      }
    }
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <Container className="days-container" sx={{ marginTop: 4 }}>
          <Typography variant="h4" gutterBottom>
            Days
          </Typography>
          <div className="add-day-button mt-4">
            <IconButton component={Link} to="/add-day" color="primary">
              <AddIcon sx={{ color: 'green' }} />
            </IconButton>
          </div>
          <Table rows={rows} columns={columns} />
        </Container>
      </div>
    </div>
  );
}

export default Days;
