// WhatsAppIcon.js
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppIcon = () => {
  const whatsappNumber = '+212671324712'; // Replace with your WhatsApp number
  const iconStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    background: '#f91942',
    padding: '10px',
    borderRadius: '50%',
    color: '#fff',
    fontSize: '25px',
    cursor: 'pointer',
    zIndex:250,
   
  };
  const handleClick = () => {
    // You can customize the WhatsApp link based on your needs
    window.open(`https://wa.me/${whatsappNumber}`, '_blank');
  };

  return (
    <div style={iconStyle} onClick={handleClick}>
      <FaWhatsapp 
    style={{
        marginBottom: '8px',
        marginLeft: '6px',
        marginRight: '6px',
        marginTop: '2px',
    }}
/>
    </div>
  );
};

export default WhatsAppIcon;
