import React, { useState, useEffect } from "react";
import TourCard from "../../shared/TourCard";
import { Link } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from "reactstrap";
import useFetch from "./../../hooks/useFetch";
import config from "./../../utils/config";
import classnames from 'classnames';
import "../../styles/tour.css";
import tourData from "../../assets/data/tours";

function FeaturedTourList() {
  const [selectedCity, setSelectedCity] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  /*const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [Tours, setDayTours] = useState([]);*/

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /*const fetchTours = async () => {
    try {
      setLoading(true); // Start loading
      const response = await config("/programs");
      const dayTour = await response;
      setDayTours(dayTour);
      setLoading(false); // Stop loading once data is loaded
    } catch (error) {
      console.log(error);
      setError("Une erreur s'est produite lors du chargement des données."); // Set error
      setLoading(false); // Stop loading in case of error
    }
  };

  useEffect(() => {
    fetchTours();
  }, []);*/

  const citiesToToursMap = {};
  tourData.forEach(tour => {
    if (!citiesToToursMap[tour.cityFrom]) {
      citiesToToursMap[tour.cityFrom] = [];
    }
    citiesToToursMap[tour.cityFrom].push(tour);
  });
  /*Tours.forEach(tour => {
    const cityName = tour.cityFrom.name; // Obtenez le nom de la ville
    if (!citiesToToursMap[cityName]) {
      citiesToToursMap[cityName] = [];
    }
    citiesToToursMap[cityName].push(tour);
  });*/

  const uniqueCities = Object.keys(citiesToToursMap);

  return (
    <div>
     { /*{loading && <h4 className="text-center pt-5">Loading........</h4>}
      {error && <h4 className="text-center pt-5">{error}</h4>}
      {!loading && !error && (
        <>*/}
          <Nav className="mb-4" tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => toggleTab('1')}
              >
                Top Tours
              </NavLink>
            </NavItem>
            {uniqueCities.slice(0, 4).map((city, index) => (
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: activeTab === index })}
                  onClick={() => toggleTab(index)}
                >
                  {city}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <TabContent activeTab={activeTab}>
            {activeTab === '1' && (
              <TabPane tabId="1">
                <Row>
                  {tourData.slice(0, 4).map(tour => (
                    <Col lg="3" md="6" sm="6" className="mb-4" key={tour.id}>
                      <TourCard tour={tour} />
                    </Col>
                  ))}
                </Row>
              </TabPane>
            )}
            {uniqueCities.map((city, index) => (
              activeTab === index && (
                <TabPane key={index} tabId={index}>
                  <Row>
                    {citiesToToursMap[city]
                      .slice(0, 4)
                      .map(tour => (
                        <Col lg="3" md="6" sm="1" className="mb-4" key={tour.id}>
                          <TourCard tour={tour} />
                        </Col>
                      ))}
                  </Row>
                </TabPane>
              )
            ))}
          </TabContent>

          <button className="show-more-button">
            <Link
              to={`/tours`}
              style={{
                textDecoration: 'none',
                color: '#fff'
              }}
            >
              Show More
            </Link>
          </button>
        {/*</>*/}
      {/*)}*/}
    </div>
  );
}

export default FeaturedTourList;
