import React, { useState, useEffect } from "react";
import ImageSlider from "../shared/ImageSlider";
import "../styles/tour.css";
import TourCard from "./../shared/TourCard";
import SearchBar from "./../shared/SearchBar";
import Newsletter from "./../shared/Newsletter";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Container } from "reactstrap";
import img from "../assets/images/tour.jpg";
import img1 from "../assets/images/sahara.jpg";
import img2 from "../assets/images/kasbah.jpg";
import img3 from "../assets/images/dardbagh.jpg";
import img4 from "../assets/images/mrzouga.jpg";
import useFetch from "../hooks/useFetch";
import config from "../utils/config";
import classnames from 'classnames';
import { Helmet } from "react-helmet-async";
import tourData from "../assets/data/tours"





const Tours = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); 
  const [selectedCity, setSelectedCity] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  const [currentPage, setCurrentPage] = useState(1);
  const [toursPerPage] = useState(12); // Number of tours to display per page

 /* const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);*/

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setCurrentPage(1); // Reset current page when changing tabs
    }
  };

 /* const [Tours, setDayTours] = useState([]);

  const fetchDayTours = async () => {
    try {
      setLoading(true); // Démarrez le chargement
      const response = await config("/programs");
      const dayTour = await response;
      setDayTours(dayTour);
      setLoading(false); // Arrêtez le chargement une fois les données chargées
    } catch (error) {
      console.log(error);
      setError("Une erreur s'est produite lors du chargement des données."); // Définir l'erreur
      setLoading(false); // Arrêtez le chargement en cas d'erreur
    }
  };

  useEffect(() => {
    fetchDayTours();
  }, []);
  */

  /**/

  const citiesToToursMap = {};
  const tours = tourData.filter(tour =>
    tour.type.some(type => type.name === "Morocco Tours")
  );
  tours.forEach(tour => {
    if (!citiesToToursMap[tour.cityFrom]) {
      citiesToToursMap[tour.cityFrom] = [];
    }
    citiesToToursMap[tour.cityFrom].push(tour);
  });
 

  // Filtrer les tours par type


  /*tours.forEach(tour => {
    const cityName = tour.cityFrom.name; // Obtenez le nom de la ville
    if (!citiesToToursMap[cityName]) {
      citiesToToursMap[cityName] = [];
    }
    citiesToToursMap[cityName].push(tour);
  });*/

  const uniqueCities = Object.keys(citiesToToursMap);

  const indexOfLastTour = currentPage * toursPerPage;
  const indexOfFirstTour = indexOfLastTour - toursPerPage;

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const ToursImages = [
    { url: img },
    { url: img1 },
    { url: img2 },
    { url: img3 },
    { url: img4 },
  ];

  return (
    <>
     <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <title>
          Morocco Happiness Tours: Experience the Best Desert Tours in Morocco
        </title>

        <meta
          name="description"
          content="Morocco Happiness Tours offers the best desert tours from Marrakech, Fez and Casablanca. Book now and explore Morocco's beautiful cities and landscapes."
        />
        <meta
          name="keywords"
          content="Morocco tours, Sahara excursions, Atlas Mountains, Fez tours, Casablanca tours, Imlil and Toubkal, 3 Valleys, Ourika Valley, Ouarzazate, Ait Ben Haddou,  Ouzoud Waterfalls, Merzouga Desert, Sahara tour, Chefchaouen, Erg Chebbi Dunes, Imperial cities, Northern Morocco"
        />

        <meta
          name="apple-mobile-web-app-title"
          content="Morocco Happiness Tours"
        />

        <link
          rel="canonical"
          href="https://www.moroccohappinesstours.com/tours"
        />

        <meta name="og:site_name" content="Morocco Happiness Tours" />
        <meta
          name="og:title"
          content="Morocco Sahara Desert Tours, Tours From Marrakech"
        />
        <meta name="og:url" content="https://www.moroccohappinesstours.com" />
        <meta
          name="og:image"
          content="https://www.moroccohappinesstours.com/img/logo.png"
        />
        <meta name="og:type" content="Travel" />
        <meta name="og:locality" content="Casablanca" />
        <meta name="og:description" content="Desert Tours from Marrakech" />

        <meta
          property="article:publisher"
          content="https://www.facebook.com/Morocco-Happiness-Tours-331099394158870/"
        />
        <meta
          property="fb:page_id"
          content="https://www.facebook.com/Morocco-Happiness-Tours-331099394158870/"
        />
        <meta property="og:site_name" content="Morocco Happiness Tours" />
        <meta
          property="og:image"
          content="https://www.moroccohappinesstours.com/images/logo.png"
        />
        <meta
          property="og:url"
          content="https://www.moroccohappinesstours.com/"
        />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Morocco Happiness Tours, Morocco Desert Tours"
        />
        <meta
          property="og:description"
          content="3 Days Tour To Merzouga Desert Via Atlas Mountain, 3 Days Tour From Marrakech Via The Sahara Desert To Fez, 4 Days Tour From Marrakesh Through Merzouga Desert To Fez, 4 Days tour from Marrakech to Marrakech via Merzouga desert, 7 Days Tour From Marrakech Via Desert And Imperial Cities"
        />
        <meta
          name="Identifier-url"
          content="https://www.moroccohappinesstours.com/"
        />
        <meta name="owner" content="moroccohappinesstours@gmail.com" />
        <meta name="Robots" content="all" />
        <meta name="Revisit-after" content="5 days" />
      </Helmet>
      <Container>
        <ImageSlider
          images={ToursImages}
          style={{
            width: "100%",
            maxHeight: "100px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Container>
      {/*{loading && <h4 className="text-center pt-5">Loading........</h4>}
            {error && <h4 className="text-center pt-5">{error}</h4>}
            {!loading && !error && (*/}
      <Container>
        <section>
          <Nav className="mb-4" tabs>
            <NavItem >
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => toggleTab('1')}
              >
                Top Tour
              </NavLink>
            </NavItem>
            {uniqueCities.map((city, index) => (
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: activeTab === index })}
                  onClick={() => toggleTab(index)}
                >
                  {city}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <TabContent activeTab={activeTab}>
            {activeTab === '1' && (
              <TabPane tabId="1">
                <Row>
                  {tours.slice(indexOfFirstTour, indexOfLastTour).map(tour => (
                    <Col lg="3" md="6" sm="6" className="mb-4" key={tour.id}>
                      <TourCard tour={tour} />
                    </Col>
                  ))}
                </Row>
                <Pagination
                  toursPerPage={toursPerPage}
                  totalTours={tours.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </TabPane>
            )}
            {uniqueCities.map((city, index) => (
              activeTab === index && (
                <TabPane key={index} tabId={index}>
                  <Row>
                    {citiesToToursMap[city]
                      .slice(indexOfFirstTour, indexOfLastTour)
                      .map(tour => (
                        <Col lg="3" md="6" sm="6" className="mb-4" key={tour.id}>
                          <TourCard tour={tour} />
                        </Col>
                      ))}
                  </Row>
                  <Pagination
                    toursPerPage={toursPerPage}
                    totalTours={citiesToToursMap[city].length}
                    currentPage={currentPage}
                    paginate={paginate}
                  />
                </TabPane>
              )
            ))}
          </TabContent>
        </section>
      </Container>{/*)}*/}

      <section>
        <Container>
          <Row>
            <SearchBar />
          </Row>
        </Container>
      </section>

      <Newsletter />
    </>
  );
};

const Pagination = ({ toursPerPage, totalTours, currentPage, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalTours / toursPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a onClick={() => paginate(1)} className="page-link common__span  ">
            <i className="ri-asterisk"></i>
          </a>
        </li>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a onClick={() => paginate(currentPage - 1)} className="page-link common__span">
            <i className="ri-arrow-left-s-line"></i>
          </a>
        </li>
        {pageNumbers.map((number, index) => (
          <li key={number} className={`page-item ${currentPage === number ? 'active__page' : ''}`}>
            {index === 0 ? (
              <a onClick={() => paginate(number)} className={`page-link common__span `}>
                {number}
              </a>
            ) : index === pageNumbers.length - 1 ? (
              <a onClick={() => paginate(number)} className={`page-link common__span `}>
                {number}
              </a>
            ) : (
              <a onClick={() => paginate(number)} className={`page-link common__span`}>
                {number}
              </a>
            )}
          </li>
        ))}
        <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
          <a onClick={() => paginate(currentPage + 1)} className="page-link common__span">
            <i className="ri-arrow-right-s-line"></i>
          </a>
        </li>
        <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
          <a onClick={() => paginate(pageNumbers.length)} className="page-link common__span">
            <i className="ri-asterisk"></i>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Tours;
